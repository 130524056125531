import { Controller } from '@hotwired/stimulus';

export default class Carousel extends Controller {
  static targets = ['item', 'nextButton', 'prevButton'];
  static classes = ['page', 'buttonDisabled'];
  static values = {
    pagination: Boolean,
    type: String,
    arrows: Boolean,
    hasPadding: Boolean,
    hasDrag: Boolean,
    hasAutoplay: Boolean,
    hasPagination: Boolean,
    paginationUrl: String,
    highestPageIndex: Number,
    isMobile: Boolean,
  };

  connect() {
    this.initializeCarousel();
  }

  async initializeCarousel() {
    const [{ Splide }] = await Promise.all([
      import(/* webpackChunkName: 'splide' */ '@splidejs/splide'),
      import(
        /* webpackChunkName: 'splide-css' */ '@splidejs/splide/dist/css/splide.min.css'
      ),
    ]);

    this.splide = new Splide(this.element, {
      type: this.typeValue,
      arrows: this.arrowsValue,
      autoplay: this.hasAutoplayValue,
      pagination: this.paginationValue,
      drag: this.hasDragValue,
      padding: this.hasPaddingValue ? { right: '2rem' } : {},
      classes: {
        page: `splide__pagination__page ${this.pageClasses.join(' ')}`,
      },
      preloadPages: 2,
      flickMaxPages: 1,
      flickPower: 30,
      rewindByDrag: false,
      rewind: false,
      perMove: 1,
      perPage: 1,
    });

    this.splide.on('pagination:mounted', (paginationData) => {
      paginationData.items.forEach((item) => {
        item.button.addEventListener('click', (event) => {
          event.stopPropagation();
          event.preventDefault();
        });
      });
    });

    if (this.hasPaginationValue) {
      this.splide.on('move', () => {
        if (this.hasNextButtonTarget && this.hasPrevButtonTarget) {
          this.nextButtonTarget.classList.add(this.buttonDisabledClass);
          this.prevButtonTarget.classList.add(this.buttonDisabledClass);
        }
      });

      this.splide.on('moved', async (index) => {
        if (this.hasNextButtonTarget && this.hasPrevButtonTarget) {
          this.nextButtonTarget.classList.remove(this.buttonDisabledClass);
          this.prevButtonTarget.classList.remove(this.buttonDisabledClass);
        }
        if (index > this.highestPageIndexValue) {
          this.highestPageIndexValue = index;
          let nextPage = index;

          const url = new URL(this.paginationUrlValue);
          url.searchParams.set('page', nextPage);
          url.searchParams.set(
            'type',
            this.isMobileValue ? 'mobile' : 'desktop'
          );

          const response = await fetch(url, {
            headers: { Accept: 'text/vnd.turbo-stream.html' },
          });

          if (response.ok) {
            const turboStream = await response.text();
            const parser = new DOMParser();

            const doc = parser.parseFromString(turboStream, 'text/html');
            const slides = doc.querySelectorAll('.more-items-container');
            if (slides.length) {
              slides.forEach((slide) => {
                this.splide.add(slide);
              });
            }
          }
        }
      });
    }

    this.splide.mount();
  }

  disconnect() {
    if (this.splide) {
      this.splide.destroy(true);
    }
  }
}
